// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/chevron-left.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../assets/images/chevron-right.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".slide-nav{background-color:#fff;background-position:50%;background-repeat:no-repeat;border-radius:100%;height:32px;margin-top:-20px;position:absolute;top:50%;width:32px}.slide-prev{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");left:5px}@media (min-width:992px){.slide-prev{left:-64px}}.slide-next{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");right:5px}@media (min-width:992px){.slide-next{right:-64px}}.loverly-carousel{overflow:hidden;position:relative}.loverly-carousel.no-mobile-nav .slide-nav{display:none}@media (min-width:992px){.loverly-carousel.no-mobile-nav .slide-nav{display:block}}.loverly-carousel.static{position:static}.loverly-carousel.bulk .keen-slider__slide{overflow:visible!important;width:auto!important}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
