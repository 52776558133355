
import 'keen-slider/keen-slider.min.css'
import KeenSlider from 'keen-slider'

export default {
    props: {
        paginationEnabled: Boolean,
        navigationEnabled: {
            type: Boolean,
            default: true,
        },

        perPage: {
            type: [String, Number],
            default: 'auto',
        },

        perPageCustom: {
            type: Array,
            default: () => {
                return []
            },
        },

        centerMode: Boolean,

        gap: {
            type: Number,
            default: 0,
        },
    },

    data() {
        return {
            current: 0,
            slider: null,
            observer: null,
        }
    },

    watch: {
        current() {
            this.$emit('current', this.current)
        }
    },

    computed: {
        breakpoints() {
            const obj = {}

            this.perPageCustom.forEach((item) => {
                const key = `(min-width: ${item[0]}px)`
                obj[key] = {
                    slides: {
                        ...this.basicSlideOptions,
                        ...{ perView: item[1] },
                    },
                }
            })

            return obj
        },
        basicSlideOptions() {
            return { spacing: this.gap, origin: this.centerMode ? 'center' : 'auto' }
        },
        smDevice() {
            return this.$store.getters['data/smDevice']
        },
    },

    mounted() {
        if (process.browser) this.init()
    },

    beforeDestroy() {
        if (this.slider) this.slider.destroy()
        if (this.observer) this.observer.disconnect()
    },

    methods: {
        init(count = 10) {
            this.$nextTick(() => {
                if (this.$refs.slider) {
                    const options = {
                        initial: this.current,
                        breakpoints: this.breakpoints,
                        slides: {
                            ...this.basicSlideOptions,
                            ...{ perView: this.perPage },
                        },
                        slideChanged: (s) => {
                            this.current = s.track.details.rel
                        },
                    }
                    this.slider = new KeenSlider(this.$refs.slider, options)
                    const observer = new MutationObserver(this.update)
                    observer.observe(this.$refs.slider, { childList: true, subtree: true })
                    this.observer = observer
                    this.update()
                } else if (count > 0) {
                    this.init(count - 1)
                }
            })
        },

        prev() {
            this.slider.prev()
        },

        next() {
            this.slider.next()
        },

        update() {
            if (this.slider) this.slider.update()
        },
    },

    render(h) {
        return h('client-only', [
            h(
                'div',
                {
                    class: ['loverly-carousel'],
                    style:
                        this.gap && this.smDevice
                            ? {
                                  padding: `0 1rem`,
                              }
                            : undefined,
                },
                [
                    h(
                        'div',
                        {
                            class: ['keen-slider'],
                            ref: 'slider',
                            props: {
                                paginationEnabled: this.paginationEnabled,
                            },
                            style:
                                this.gap && this.smDevice
                                    ? {
                                          overflow: `visible`,
                                      }
                                    : undefined,
                        },
                        [
                            this.$slots.default
                                ? this.$slots.default.map((el) => {
                                      return h(
                                          'div',
                                          {
                                              class: ['keen-slider__slide'],
                                          },
                                          [el]
                                      )
                                  })
                                : null,
                        ]
                    ),
                    this.navigationEnabled
                        ? h('div', { class: 'slider-navigation' }, [
                              h('button', {
                                  class: ['slide-nav', 'slide-prev', { 'cursor-not-allowed pointer-events-none opacity-50': this.current === 0 }],
                                  on: {
                                      click: this.prev,
                                  },
                              }),
                              h('button', {
                                  class: [
                                      'slide-nav',
                                      'slide-next',
                                      {
                                          'cursor-not-allowed pointer-events-none opacity-50':
                                              this.current === this.slider?.track?.details?.slides?.length - 1 ||
                                              this.slider?.track?.details?.length === this.slider?.track?.details?.position,
                                      },
                                  ],
                                  on: {
                                      click: this.next,
                                  },
                              }),
                          ])
                        : null,
                ]
            ),
        ])
    },
}
